html {
  scroll-behavior: smooth;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "font-bold";
  src: url("./assets/fonts/Poppins-Bold.woff");
}

@font-face {
  font-family: "font-regular";
  src: url("./assets/fonts/Poppins-Medium.woff");
}

@font-face {
  font-family: "font-medium";
  src: url("./assets/fonts/Poppins-Medium.woff");
}

@font-face {
  font-family: "font-light";
  src: url("./assets/fonts/Poppins-Light.woff");
}

.font-bold {
  font-family: "font-bold";
}
.font-regular {
  font-family: "font-regular";
}
.font-medium {
  font-family: "font-medium";
}
.font-light {
  font-family: "font-light";
}

.main-bg {
  background-image: url("./assets/svgs/wave-haikei-large.svg");
  background-position: center;
  background-size: cover;
  height: 150rem;
  width: 100% !important;
  top: 0;
}

.specialists-bg {
  background-image: url("./assets/svgs/wave-haikei-large.svg");
  background-position: center;
  background-size: cover;
  height: 100rem;
  width: 100% !important;
  top: 0;
}

.company-bg {
  background-image: url("./assets/svgs/wave-haikei-large.svg");
  background-position: center;
  background-size: cover;
  height: 102rem;
  width: 100% !important;
  top: 0;
}

.manada-bg {
  background-image: url("./assets/images/manada.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 600px;
  /* height: 352px; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (max-width: 767px) {
  .manada-bg {
    background-size: auto;
    height: 400px;
  }
}
.terms {
  font-family: "Times New Roman", Times, serif;
}

summary::-webkit-details-marker {
  display: none;
}
